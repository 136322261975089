import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { NodeLicenseChangeableData } from "src/features/licenses/licenses.defenitions";
import { UserRole } from "src/general.defenitions";
import { RootState } from "src/store";
import {
  nodeRecordsSelectors,
  setPendingChanges,
} from "../../node-tree-records.slice";
import { NodeType } from "../../node-tree.defenitions";
import "./NodeLicenses.scss";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import AllocateLicenseSwitch from "./AllocateLicenseSwitch";
import AllocateLicenseList from "./AllocateLicenseList";
import NodeLicenseList from "./NodeLicenseList";
const { cloneDeep } = require("lodash");

const NodeLicenses = () => {
  const dispatch = useDispatch();
  const [allocateLicenses, setAllocateLicenses] = useState<boolean>(false);
  const [mergedLicenses, setMergedLicenses] =
    useState<NodeLicenseChangeableData>();
  const [currentNodeIsRoot, setCurrentNodeIsRoot] = useState<boolean>(false);
  const [licenseViewOnly, setLicenseViewOnly] = useState<boolean>(false);

  const currentNodeLicenseInfo = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeInfo?.licenses
  );

  const pendingChanges = useSelector(
    (state: RootState) => state.nodeRecords.pendingChanges
  );

  const selectedNodeId = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeId
  );

  const selectedNode = useSelector((state: RootState) =>
    selectedNodeId
      ? nodeRecordsSelectors.selectById(state.nodeRecords, selectedNodeId)
      : undefined
  );

  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );

  const userRoles = useSelector((state: RootState) => state.user.roles);

  // when we're back from edit mode,
  // we want to reset the allocate licenses state
  useEffect(() => {
    setAllocateLicenses(false);
  }, [editCurrentNode]);

  // if the current node is root,
  // we don't let the user allocate licenses for this node
  useEffect(() => {
    const userRoleIsRoot = userRoles
      .filter(
        (role) =>
          role.role === UserRole.ADMIN || role.role === UserRole.SYSADMIN
      )
      .find((role) => role.nodeId === selectedNodeId);
    setCurrentNodeIsRoot(!!userRoleIsRoot);
  }, [userRoles, selectedNodeId]);

  // if the current node is under school node,
  // we don't let the user allocate licenses for this node
  // and assign viewOnly
  useEffect(() => {
    if (!selectedNode) return;
    if (
      selectedNode.type === NodeType.YEAR ||
      selectedNode.type === NodeType.GRADE ||
      selectedNode.type === NodeType.CLASS
    ) {
      setLicenseViewOnly(true);
    } else {
      setLicenseViewOnly(false);
    }
  }, [selectedNode]);

  // merge pending changes
  useEffect(() => {
    const nodeLicensesIds: number[] = cloneDeep(
      pendingChanges?.licenses?.nodeLicensesIds ||
        currentNodeLicenseInfo?.nodeLicensesIds ||
        []
    );

    nodeLicensesIds.push(0);

    let scienceActiveLicenseId: number | null =
      currentNodeLicenseInfo?.scienceActiveLicenseId === undefined
        ? null
        : currentNodeLicenseInfo?.scienceActiveLicenseId;

    if (pendingChanges?.licenses?.scienceActiveLicenseId !== undefined) {
      scienceActiveLicenseId = pendingChanges?.licenses?.scienceActiveLicenseId;
    }

    let ctActiveLicenseId: number | null =
      currentNodeLicenseInfo?.ctActiveLicenseId === undefined
        ? null
        : currentNodeLicenseInfo?.ctActiveLicenseId;

    if (pendingChanges?.licenses?.ctActiveLicenseId !== undefined) {
      ctActiveLicenseId = pendingChanges?.licenses?.ctActiveLicenseId;
    }

    const mergedLicenses: NodeLicenseChangeableData = {
      nodeLicensesIds: Array.from(new Set(nodeLicensesIds)),
      ctActiveLicenseId,
      scienceActiveLicenseId,
    };
    console.log("mergedLicenses", mergedLicenses);
    setMergedLicenses(mergedLicenses);
  }, [currentNodeLicenseInfo, pendingChanges]);

  const onAllocatedLicenseSelect = (
    licenseId: number,
    selected: boolean | undefined
  ) => {
    let newNodeLicenses = cloneDeep(
      mergedLicenses?.nodeLicensesIds || []
    ) as number[];
    console.log("onAllocatedLicenseSelect", {
      licenseId,
      selected,
      newNodeLicenses,
    });
    if (selected) {
      newNodeLicenses.push(licenseId);
    } else {
      newNodeLicenses = newNodeLicenses.filter((id) => id !== licenseId);
    }
    console.log("onAllocatedLicenseSelect after", {
      newNodeLicenses,
    });
    dispatch({
      type: setPendingChanges.type,
      payload: {
        licenses: {
          // remove duplicates
          ...mergedLicenses,
          nodeLicensesIds: Array.from(new Set(newNodeLicenses)),
        },
      },
    });
  };

  return (
    <div className="node-licenses">
      <div className="node-licenses__header">
        <div>
          {!licenseViewOnly && (
            <AllocateLicenseSwitch
              allocateLicenses={allocateLicenses}
              editCurrentNode={editCurrentNode}
              setAllocateLicenses={setAllocateLicenses}
            />
          )}
        </div>
      </div>
      <div className="node-license__list">
        {allocateLicenses ? (
          <AllocateLicenseList
            licenses={currentNodeLicenseInfo?.adminLicenses}
            selectedLicenses={mergedLicenses?.nodeLicensesIds}
            viewOnly={currentNodeIsRoot || licenseViewOnly}
            onAllocatedLicenseSelect={onAllocatedLicenseSelect}
          />
        ) : (
          <NodeLicenseList
            mergedLicenses={mergedLicenses}
            ctSelectedLicenseId={mergedLicenses?.ctActiveLicenseId}
            scienceSelectedLicenseId={mergedLicenses?.scienceActiveLicenseId}
            nodeLicensesIds={mergedLicenses?.nodeLicensesIds}
            licenses={currentNodeLicenseInfo?.adminLicenses}
            viewOnly={licenseViewOnly}
          />
        )}
      </div>
    </div>
  );
};

export default NodeLicenses;
