import React, { useState } from "react";

const HiddenValue = ({ value }: HiddenValueProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const hiddenValue = isHovered ? value : "*".repeat(value.toString().length);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {hiddenValue}
    </div>
  );
};

export default HiddenValue;

interface HiddenValueProps {
  value: string | number;
}
