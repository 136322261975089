import { ArrowLeftOutlined, CheckSquareOutlined } from "@ant-design/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import PrimaryButton from "src/ui/buttons/PrimaryButton";

const AllocateLicenseSwitch = ({
  allocateLicenses,
  editCurrentNode,
  setAllocateLicenses,
}: AllocateLicenseProps) => {
  return (
    <>
      {!allocateLicenses && (
        <PrimaryButton
          type="link"
          icon={<CheckSquareOutlined />}
          disabled={!editCurrentNode}
          onClick={() => {
            setAllocateLicenses(!allocateLicenses);
          }}
        >
          <FormattedMessage id="nodes.node.allocateLicenses" />
        </PrimaryButton>
      )}
      {allocateLicenses && (
        <PrimaryButton
          type="link"
          icon={<ArrowLeftOutlined />}
          disabled={!editCurrentNode}
          onClick={() => {
            setAllocateLicenses(!allocateLicenses);
          }}
        >
          <FormattedMessage id="general.back" />
        </PrimaryButton>
      )}
    </>
  );
};

export default AllocateLicenseSwitch;

interface AllocateLicenseProps {
  allocateLicenses: boolean;
  editCurrentNode: boolean;
  setAllocateLicenses: (value: boolean) => void;
}
