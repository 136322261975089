import React, { useEffect } from "react";
import { selectFilteredLicenses, setSelectedLicenses } from "./licenses.slice";
import { useDispatch, useSelector } from "react-redux";
import { Button, Space, Table, Tag, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import {
  ContentPackage,
  License,
  LicenseFormItem,
  LicenseWithNodeInfo,
} from "./licenses.defenitions";
import { FormattedMessage } from "react-intl";
import LicenseTableActions from "./LicenseTableActions";
import "./LicensesView.scss";
import { PlethoraApp } from "src/general.defenitions";
import { AppDispatch, RootState } from "src/store";
import EditableRow from "./EditableRow";
import EditableTextCell from "./EditableTextCell";
import EditableDateCell from "./EditableDateCell";
import TableCell from "./TableCell";
import EditableSelectCell from "./EditableSelectCell";
import { clearPendingLicense, updateLicense } from "./pending-licenses.slice";
import { UndoOutlined } from "@ant-design/icons";
import LicenseTableActiveNodes from "./LicenseTableActiveNodes";

const LicensesList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [lisenseFormItems, setLicenseFormItems] = React.useState<
    LicenseFormItem[]
  >([]);

  const licenses = useSelector(selectFilteredLicenses);

  const pendingLicenses = useSelector(
    (state: RootState) => state.unsavedLicensesSlice.entities
  );

  const scienceContentPackages = useSelector(
    (state: RootState) => state.sysAdminLicenses.scienceContentPackages
  );
  const ctContentPackages: ContentPackage[] = [];

  useEffect(() => {
    const licenseFormItems: LicenseFormItem[] = licenses
      .map((license) =>
        pendingLicenses[license.id] ? pendingLicenses[license.id] : license
      )
      .filter((license): license is License => !!license)
      .map((license: LicenseWithNodeInfo) => {
        let contentPackage = null;
        if (license.data?.contentPackage?.id) {
          if (license.product === PlethoraApp.SCIENCE) {
            contentPackage = scienceContentPackages.find(
              (cp) => cp.id === license.data?.contentPackage?.id
            );
          }
          if (license.product === PlethoraApp.CT) {
            contentPackage = ctContentPackages.find(
              (cp) => cp.id === license.data?.contentPackage?.id
            );
          }
        }
        return {
          key: license.id,
          id: license.id,
          name: license.name,
          product: license.product,
          expirationDate: license.expirationDate,
          period: license.period,
          maxUsers: license.maxUsers,
          currentUsers: license.currentUsers,
          contentPackageId: contentPackage?.id || null,
          activeNodes: license.activeNodes,
          contentPackageLessons:
            (contentPackage?.lessons || []).join(", ") || "",
        };
      });

    setLicenseFormItems(licenseFormItems);
  }, [licenses, pendingLicenses, scienceContentPackages, ctContentPackages]);

  const components = {
    body: {
      row: EditableRow,
      cell: TableCell,
    },
  };

  const handleDiscardEdit = (id: number) => () => {
    dispatch({ type: clearPendingLicense.type, payload: id });
  };

  const tableColumns: ColumnType<LicenseFormItem>[] = [
    {
      title: <FormattedMessage id="licenses.id" />,
      dataIndex: "id",
      key: "id",
      render: (_, { id }) => {
        if (id > 0) return id;
        return "-";
      },
    },
    {
      title: <FormattedMessage id="licenses.name" />,
      dataIndex: "name",
      key: "name",
      render: (text: any, record: LicenseFormItem) => (
        <EditableTextCell
          title={<FormattedMessage id="licenses.name" />}
          dataIndex={"name"}
          record={record}
          type={"string"}
          handleSave={handleSave}
        >
          {text}
        </EditableTextCell>
      ),
    },
    {
      title: <FormattedMessage id="licenses.expirationDate" />,
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (_, record: LicenseFormItem) => (
        <EditableDateCell
          title={<FormattedMessage id="licenses.expirationDate" />}
          dataIndex={"expirationDate"}
          record={record}
          width={160}
          handleSave={handleSave}
        >
          <>{record.expirationDate as unknown as string}</>
        </EditableDateCell>
      ),
    },
    {
      title: <FormattedMessage id="licenses.product" />,
      dataIndex: "product",
      key: "product",
      render: (_, record: LicenseFormItem) => (
        <EditableSelectCell
          title={<FormattedMessage id="licenses.product" />}
          dataIndex="product"
          record={record}
          initialValue={record.product}
          width={120}
          handleSave={handleSave}
          disabled={record.id > 0}
          options={[
            {
              label: (
                <Tag color="#5d4fff" key={PlethoraApp.SCIENCE}>
                  {PlethoraApp.SCIENCE.toUpperCase()}
                </Tag>
              ),
              value: PlethoraApp.SCIENCE,
            },
            {
              label: (
                <Tag color="#dd4401" key={PlethoraApp.CT}>
                  {PlethoraApp.CT.toUpperCase()}
                </Tag>
              ),
              value: PlethoraApp.CT,
            },
          ]}
        />
      ),
    },
    {
      title: <FormattedMessage id="licenses.period" />,
      dataIndex: "period",
      key: "period",
      render: (text: any, record: LicenseFormItem) => (
        <EditableTextCell
          title={<FormattedMessage id="licenses.period" />}
          dataIndex={"period"}
          record={record}
          width={60}
          type={"number"}
          handleSave={handleSave}
        >
          {text}
        </EditableTextCell>
      ),
    },
    {
      title: <FormattedMessage id="licenses.maxUsers" />,
      dataIndex: "maxUsers",
      key: "maxUsers",
      render: (text: any, record: LicenseFormItem) => (
        <EditableTextCell
          title={<FormattedMessage id="licenses.maxUsers" />}
          dataIndex={"maxUsers"}
          record={record}
          width={60}
          type={"number"}
          handleSave={handleSave}
        >
          {text}
        </EditableTextCell>
      ),
    },
    {
      title: <FormattedMessage id="licenses.currentUsers" />,
      dataIndex: "currentUsers",
      key: "currentUsers",
    },
    {
      title: <FormattedMessage id="licenses.contentPackage" />,
      dataIndex: "contentPackageId",
      key: "contentPackageId",
      render: (_, record: LicenseFormItem) => (
        <EditableSelectCell
          title={<FormattedMessage id="licenses.contentPackage" />}
          dataIndex="contentPackageId"
          record={record}
          width={120}
          handleSave={handleSave}
          initialValue={record.contentPackageId}
          options={[...scienceContentPackages, ...ctContentPackages].map(
            (contentPackage) => {
              return {
                value: contentPackage.id,
                label: contentPackage.name + " " + contentPackage.id,
              };
            }
          )}
        />
      ),
    },
    {
      title: <FormattedMessage id="licenses.assignedToNodes" />,
      dataIndex: "activeNodes",
      key: "activeNodes",
      render: (_, { activeNodes }) => (
        <LicenseTableActiveNodes activeNodes={activeNodes || []} />
      ),
    },
    {
      title: <FormattedMessage id="licenses.contentPackageLessons" />,
      dataIndex: "contentPackageLessons",
      key: "contentPackageLessons",
      render: (_, { contentPackageLessons }) => {
        if (contentPackageLessons) {
          if (contentPackageLessons.length > 45) {
            return (
              <Tooltip title={contentPackageLessons}>
                {contentPackageLessons.substring(0, 45) + "..."}
              </Tooltip>
            );
          } else {
            return contentPackageLessons;
          }
        }
        return "-";
      },
    },
    {
      title: <FormattedMessage id="licenses.actions" />,
      dataIndex: "actions",
      key: "actions",
      render: (_, record: LicenseFormItem) => (
        <Space size="middle">
          {record.id > 0 && pendingLicenses[record.id] && (
            <Button
              type="primary"
              icon={<UndoOutlined />}
              onClick={handleDiscardEdit(record.id)}
            />
          )}
        </Space>
      ),
    },
  ];

  const handleSave = (row: LicenseFormItem) => {
    const contentPackage = scienceContentPackages.find(
      (cp) => cp.id === row.contentPackageId
    );
    dispatch({
      type: updateLicense.type,
      payload: { pendingLicense: row, contentPackage },
    });
  };

  const onRowSelectionChange = (
    selectedRowKeys: React.Key[],
    selectedRows: LicenseFormItem[]
  ) => {
    dispatch({ type: setSelectedLicenses.type, payload: selectedRowKeys });
  };

  return (
    <div className="table-wrapper">
      <LicenseTableActions />
      <Table
        components={components}
        columns={tableColumns}
        dataSource={lisenseFormItems}
        rowSelection={{
          type: "checkbox",
          onChange: onRowSelectionChange,
        }}
      />
    </div>
  );
};

export default LicensesList;
