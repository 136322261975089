import { PlusOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { MenuInfo } from "src/general.defenitions";
import { AppDispatch } from "src/store";
import PrimaryButton from "src/ui/buttons/PrimaryButton";
import { addNode } from "../node-tree-records.slice";
import { NodeRecord, NodeType } from "../node-tree.defenitions";

const TreeItemActions = ({
  nodeId,
  nodeType,
  nodeKey,
}: TreeItemActionsProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [addActions, setAddActions] = useState<ItemType[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const addActions = [];
    if (nodeType === NodeType.GRADE) {
      addActions.push({
        key: NodeType.CLASS,
        label: formatMessage({ id: "nodes.types.class" }),
      });
    }

    if (nodeType === NodeType.YEAR) {
      addActions.push({
        key: NodeType.GRADE,
        label: formatMessage({ id: "nodes.types.grade" }),
      });
    }
    if (nodeType === NodeType.SCHOOL) {
      addActions.push({
        key: NodeType.YEAR,
        label: formatMessage({ id: "nodes.types.year" }),
      });
    }
    if (nodeType === NodeType.GROUP) {
      addActions.push({
        key: NodeType.SCHOOL,
        label: formatMessage({ id: "nodes.types.school" }),
      });
      addActions.push({
        key: NodeType.GROUP,
        label: formatMessage({ id: "nodes.types.group" }),
      });
    }
    setAddActions(addActions);
  }, [nodeType]);

  const onAddMenuClick = ({ key }: MenuInfo) => {
    const nodeType = key as NodeType;
    const tempId = Date.now();
    const nodeRecord: NodeRecord = {
      id: tempId,
      key: nodeKey + tempId,
      isLeaf: true,
      parentNodeId: nodeId,
      externalId: null,
      title: "",
      name: "",
      type: nodeType,
      newNode: true,
      codes: [],
      data: {},
    };

    dispatch({ type: addNode.type, payload: nodeRecord });
  };

  const preventDefault = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div onClick={preventDefault}>
      {addActions.length > 0 && (
        <Dropdown
          menu={{ items: addActions, onClick: onAddMenuClick }}
          trigger={["click"]}
        >
          <PrimaryButton type="link" size="small" icon={<PlusOutlined />} />
        </Dropdown>
      )}
    </div>
  );
};

export default TreeItemActions;

interface TreeItemActionsProps {
  nodeId: number;
  nodeType: NodeType;
  nodeKey: string;
}
