import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import { authenticateWithPassword } from "src/features/auth/auth.api";
import { AvailableLanguages, UserToken } from "../general.defenitions";

const initialState: PersistentUser = {
  language: AvailableLanguages.EN,
  token: null,
  tokenExpires: null,
};

const slice = createSlice({
  name: "persistentUser",
  initialState,
  reducers: {
    setLanguage: (
      slice: PersistentUser,
      action: PayloadAction<AvailableLanguages>
    ) => {
      slice.language = action.payload;
    },
    updateToken: (
      slice: PersistentUser,
      action: PayloadAction<string | null>
    ) => {
      if (action.payload) {
        const decodedToken: UserToken = jwtDecode(action.payload);
        slice.token = action.payload;
        slice.tokenExpires = decodedToken.expires;
      } else {
        slice.token = null;
        slice.tokenExpires = null;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      authenticateWithPassword.pending,
      (slice: PersistentUser) => {
        slice.token = null;
        slice.tokenExpires = null;
      }
    );

    builder.addCase(
      authenticateWithPassword.fulfilled,
      (slice: PersistentUser, action: PayloadAction<string>) => {
        const decodedToken: UserToken = jwtDecode(action.payload);
        slice.token = action.payload;
        slice.tokenExpires = decodedToken.expires;
      }
    );

    builder.addCase(
      authenticateWithPassword.rejected,
      (slice: PersistentUser, action: PayloadAction<any>) => {
        slice.token = null;
        slice.tokenExpires = null;
      }
    );
  },
});

export const { setLanguage, updateToken } = slice.actions;
export default slice.reducer;

export interface PersistentUser {
  language: AvailableLanguages;
  token: string | null;
  tokenExpires: number | null;
}
