import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React from "react";
import RootNodes from "./RootNodes";
import "./NodeTree.scss";
import SelectedItem from "./selected-item/SelectedItem";

const NodeTreeView = () => {
  return (
    <Layout className="node-tree">
      <Sider collapsible collapsedWidth={200} width={400}>
        <RootNodes />
      </Sider>
      <Content style={{ overflow: "auto" }}>
        <SelectedItem />
      </Content>
    </Layout>
  );
};

export default NodeTreeView;
