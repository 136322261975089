import { combineReducers } from "@reduxjs/toolkit";
import user from "./user.slice";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistentUser from "./persistent.slice";
import nodeRecords from "../features/node-tree/node-tree-records.slice";
import nodeRecordUsers from "../features/node-tree/node-item-users.slice";
import sysAdminLicenses from "../features/licenses/licenses.slice";
import unsavedLicensesSlice from "../features/licenses/pending-licenses.slice";

const persistConfig = {
  key: "plethora-admin-tools",
  storage,
  whitelist: ["persistentUser"],
};

const rootReducer = combineReducers({
  user,
  persistentUser,
  nodeRecords,
  nodeRecordUsers,
  sysAdminLicenses,
  unsavedLicensesSlice,
});

export default persistReducer(persistConfig, rootReducer);
