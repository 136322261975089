import { Form, Select } from "antd";
import { ReactElement, useContext, useEffect, useState } from "react";
import { EditableContext } from "./EditableRow";
import { LicenseFormItem } from "./licenses.defenitions";
import { ColumnTitle } from "antd/lib/table/interface";
import "./Editable.scss";

const EditableTextCell: React.FC<EditableCellProps> = ({
  title,
  dataIndex,
  record,
  options,
  width = "auto",
  handleSave,
  placeholder,
  initialValue,
  disabled,
}) => {
  const [editing, setEditing] = useState(false);
  const [selectedValue, setSelectedValue] = useState<{
    value: string | number;
    label: ReactElement | string;
  } | null>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    form.setFieldValue(dataIndex, record[dataIndex]);
  }, [record[dataIndex]]);

  useEffect(() => {
    if (disabled) {
      const option = options.find((option) => option.value === initialValue);
      setSelectedValue(option || null);
    }
  }, [disabled, options, initialValue]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: initialValue });
  };

  const save = async () => {
    setEditing(false);
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values, changed: true });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  return disabled ? (
    <div className="editable-cell-value-wrap">{selectedValue?.label}</div>
  ) : (
    <Form.Item
      style={{ margin: 0, width }}
      name={dataIndex}
      // rules={[
      //   {
      //     required: true,
      //     message: `${title} is required.`,
      //   },
      // ]}
      initialValue={initialValue}
    >
      <Select
        className="table-select"
        options={options}
        placeholder={placeholder}
        disabled={disabled}
        onChange={save}
      ></Select>
    </Form.Item>
  );
};

interface EditableCellProps {
  title: ColumnTitle<LicenseFormItem>;
  width?: string | number;
  dataIndex: keyof LicenseFormItem;
  placeholder?: ReactElement;
  record: LicenseFormItem;
  initialValue?: string | number | null;
  disabled?: boolean;
  options: { value: string | number; label: ReactElement | string }[];
  handleSave: (record: LicenseFormItem) => void;
}

export default EditableTextCell;
