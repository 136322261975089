import { Form, FormInstance } from "antd";
import React from "react";
import "./Editable.scss";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();

  // here we use react context because we don't need redux and I don't want
  // to pollute the state with local data

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export default EditableRow;

interface EditableRowProps {
  index: number;
}
