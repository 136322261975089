import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ChevronDown from "src/ui/icons/ChevronDown";
import ChinaFlagIcon from "src/ui/icons/flags/ChinaFlagIcon";
import UkFlagIcon from "src/ui/icons/flags/UkFlagIcon";
import IsraelFlagIcon from "src/ui/icons/flags/IsraelFlagIcon";
import FranceFlagIcon from "src/ui/icons/flags/FranceFlagIcon";
import ItalyFlagIcon from "src/ui/icons/flags/ItalyFlagIcon";
import JapanFlagIcon from "src/ui/icons/flags/JapanFlagIcon";
import PortugalFlagIcon from "src/ui/icons/flags/PortugalFlagIcon";
import BrazilFlagIcon from "src/ui/icons/flags/BrazilFlagIcon";
import SaudiFlagIcon from "src/ui/icons/flags/SaudiFlagIcon";
import SpainFlagIcon from "src/ui/icons/flags/SpainFlagIcon";
import { AvailableLanguages } from "src/general.defenitions";
import { RootState } from "src/store";

const LanguageSwitcher = () => {
  const dispatch = useDispatch();
  const currentLanguage: AvailableLanguages = useSelector(
    (state: RootState) => state.persistentUser.language
  );

  // this is not wrapped into useEffect because it's redundant, component
  // will rerender every time useSelector is called.
  let activeLanguageIcon;

  switch (currentLanguage) {
    case "en":
      activeLanguageIcon = <UkFlagIcon />;
      break;
    case "he":
      activeLanguageIcon = <IsraelFlagIcon />;
      break;
    case "fr":
      activeLanguageIcon = <FranceFlagIcon />;
      break;
    case "it":
      activeLanguageIcon = <ItalyFlagIcon />;
      break;
    case "jp":
      activeLanguageIcon = <JapanFlagIcon />;
      break;
    case "pt":
      activeLanguageIcon = <PortugalFlagIcon />;
      break;
    case "pt-BR":
      activeLanguageIcon = <BrazilFlagIcon />;
      break;
    case "cn":
      activeLanguageIcon = <ChinaFlagIcon />;
      break;
    case "ar-SA":
      activeLanguageIcon = <SaudiFlagIcon />;
      break;
    case "es":
      activeLanguageIcon = <SpainFlagIcon />;
      break;
  }

  const handleLanguageSelection = ({ key }: any) => {
    dispatch({ type: "persistentSlice/setLanguage", payload: key });
  };

  const items = [
    {
      key: "en",
      icon: <UkFlagIcon />,
      label: "English",
    },
    {
      key: "he",
      icon: <IsraelFlagIcon />,
      label: "עברית",
    },
    {
      key: "ar-SA",
      icon: <SaudiFlagIcon />,
      label: "العربية",
      disabled: true,
    },
    {
      key: "pt-BR",
      icon: <BrazilFlagIcon />,
      label: "Português brasileiro",
      disabled: true,
    },
    {
      key: "fr",
      icon: <FranceFlagIcon />,
      label: "Français",
      disabled: true,
    },
    {
      key: "it",
      icon: <ItalyFlagIcon />,
      label: "Italiano",
      disabled: true,
    },
    {
      key: "jp",
      icon: <JapanFlagIcon />,
      label: "日本語",
      disabled: true,
    },
    {
      key: "pt",
      icon: <PortugalFlagIcon />,
      label: "Português",
      disabled: true,
    },
    {
      key: "cn",
      icon: <ChinaFlagIcon />,
      label: "中文",
      disabled: true,
    },
    {
      key: "es",
      icon: <SpainFlagIcon />,
      label: "Español",
      disabled: true,
    },
  ];

  return (
    <Dropdown menu={{ items, onClick: handleLanguageSelection }}>
      <Button
        onClick={(e) => e.preventDefault()}
        shape="circle"
        type="text"
        icon={activeLanguageIcon}
      >
        <ChevronDown />
      </Button>
    </Dropdown>
  );
};

export default LanguageSwitcher;
