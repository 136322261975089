export interface UserToken {
  id: number;
  externalId: string | null;
  username: string;
  data: UserData;
  roles: UserRoleRecord[];
  expires: number;
}

export interface UserRoleRecord {
  role: UserRole;
  nodeId: number;
}

export interface UserData {
  age: number;
  fullName: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
}

export interface AuthenticateWithPasswordRequest {
  username: string;
  password: string;
}

export enum UserRole {
  SYSADMIN = "sysAdmin",
  ADMIN = "admin",
  TEACHER = "teacher",
  STUDENT = "student",
  PARENT = "parent",
  CONTENT_PROVIDER = "contentProvider",
}

export enum AvailableLanguages {
  EN = "en",
  HE = "he",
  FR = "fr",
  PT = "pt",
  PT_BR = "pt-BR",
  IT = "it",
  CN = "cn",
  JP = "jp",
  AR_SA = "ar-SA",
  ES = "es",
}

export interface MenuInfo {
  key: string;
  keyPath: string[];
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
}

export enum PlethoraApp {
  ALL = "all",
  CT = "ct",
  SCIENCE = "science",
}
