import { PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { deleteSelectedLicenses, licensesSelectors } from "./licenses.slice";
import { deleteLicensesApi, putLicensesUpdateApi } from "./licenses.api";
import {
  LicenseUpsertResponse,
  SaveLisensesResponse,
} from "./licenses.defenitions";

export const saveLicenses = createAsyncThunk<
  SaveLisensesResponse,
  undefined,
  { state: RootState }
>("saveLicenses", async (param, { getState, dispatch }) => {
  const state = getState();
  const pendingLicenses = licensesSelectors.selectAll(
    state.unsavedLicensesSlice
  );

  const response = (await dispatch(
    putLicensesUpdateApi(pendingLicenses)
  )) as PayloadAction<LicenseUpsertResponse>;

  return { pendingLicenses, addedIds: response.payload.addedIds };
});

export const deleteLicenses = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("deleteLicenses", async (param, { getState, dispatch }) => {
  // first we need to get all the selected licenses from the state
  // then send them to the server
  // then remove them from the state

  const state = getState();
  const selectedLicenses = state.sysAdminLicenses.selectedLicenses.map((id) =>
    parseInt(id)
  );

  dispatch(deleteLicensesApi(selectedLicenses))
    .unwrap()
    .then(() => {
      console.log("licenses deleted");
      dispatch({ type: deleteSelectedLicenses.type });
    });
});
