import React from "react";
import { FormattedMessage } from "react-intl";
import HiddenValue from "./HiddenValue";
import "./ValueWithLabel.scss";
import { Input } from "antd";

const ValueWithLabel = ({
  label,
  value,
  valueHidden,
  edit,
  onValueChange,
  width,
}: ValueWithLabelProps) => {
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.target.value;
    onValueChange && onValueChange(newValue);
  };

  return (
    <div className="value-with-label" style={{ width: `${width}px` }}>
      {label && (
        <span className="value-with-label__label">
          <FormattedMessage id={label} />:{" "}
        </span>
      )}
      {!edit && <>{valueHidden ? <HiddenValue value={value} /> : value}</>}
      {edit && <Input value={value} onChange={onChange} />}
    </div>
  );
};

export default ValueWithLabel;

interface ValueWithLabelProps {
  label?: string;
  width: number;
  value: string | number;
  edit?: boolean;
  onValueChange?: (newValue: string) => void;
  valueHidden?: boolean;
}
