import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserRole, UserRoleRecord } from "src/general.defenitions";
import { AppDispatch, RootState } from "src/store";
import LicensesList from "./LicensesList";
import "./LicensesView.scss";
import { getAllLicensesApi } from "./licenses.api";

const LicensesView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const userRoles = useSelector(
    (state: RootState) => state.user.roles
  ) as UserRoleRecord[];

  useEffect(() => {
    const isSysadmin = userRoles
      .map((record) => record.role)
      .includes(UserRole.SYSADMIN);
    if (!isSysadmin) {
      navigate("/node-tree");
    }
    dispatch(getAllLicensesApi());
  }, [userRoles]);

  return (
    <Layout className="node-tree">
      <Content>
        <LicensesList />
      </Content>
    </Layout>
  );
};

export default LicensesView;
