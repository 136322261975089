import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  License,
  NodeLicenseChangeableData,
} from "src/features/licenses/licenses.defenitions";
import { PlethoraApp } from "src/general.defenitions";
import { setPendingChanges } from "../../node-tree-records.slice";
import LicenseItem from "./LicenseItem";

interface NodeLicenseAppGroupProps {
  product: PlethoraApp;
  mergedLicenses: NodeLicenseChangeableData | undefined;
  licenses: License[] | undefined;
  selectedLicenseId: number | null | undefined;
  assumedLicense: License | null | undefined;
  viewOnly?: boolean;
}

const NodeLicenseAppGroup: React.FC<NodeLicenseAppGroupProps> = ({
  product,
  mergedLicenses,
  licenses,
  selectedLicenseId,
  assumedLicense,
}) => {
  const dispatch = useDispatch();

  const handleLicenseSelect = (licenseId: number, selected?: boolean) => {
    const selectedAssumedLicense =
      assumedLicense && assumedLicense.id === licenseId;
    const selectedNoLicense = licenseId === -1;
    if (selected && !selectedAssumedLicense && !selectedNoLicense) {
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            [product === PlethoraApp.CT
              ? "ctActiveLicenseId"
              : "scienceActiveLicenseId"]: licenseId,
          },
        },
      });
    }

    if (selectedNoLicense) {
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            ...mergedLicenses,
            [product === PlethoraApp.CT
              ? "ctActiveLicenseId"
              : "scienceActiveLicenseId"]: selected ? 0 : null,
          },
        },
      });
    }
    if (selectedAssumedLicense) {
      dispatch({
        type: setPendingChanges.type,
        payload: {
          licenses: {
            [product === PlethoraApp.CT
              ? "ctActiveLicenseId"
              : "scienceActiveLicenseId"]: null,
          },
        },
      });
    }
  };

  return (
    <div>
      <LicenseItem
        license={
          {
            id: -1,
            name: "No License",
            maxUsers: 0,
            currentUsers: 0,
            expirationDate: "",
          } as License
        }
        allocateMode={false}
        selected={selectedLicenseId === -1 || selectedLicenseId === 0}
        onSelect={handleLicenseSelect}
      />
      {assumedLicense && (
        <LicenseItem
          license={assumedLicense}
          assumedLicense={true}
          allocateMode={false}
          selected={assumedLicense && selectedLicenseId === null}
          onSelect={handleLicenseSelect}
        />
      )}
      {licenses &&
        licenses.map((license) => (
          <LicenseItem
            key={license.id}
            license={license}
            allocateMode={false}
            selected={selectedLicenseId === license.id}
            onSelect={handleLicenseSelect}
          />
        ))}
    </div>
  );
};

export default NodeLicenseAppGroup;
