import { License } from "src/features/licenses/licenses.defenitions";
import LicenseItem from "./LicenseItem";
import { Collapse } from "antd";
import { PlethoraApp } from "src/general.defenitions";
import { FormattedMessage } from "react-intl";
import "./NodeLicenses.scss";

const { Panel } = Collapse;

const AllocateLicenseList = ({
  licenses,
  selectedLicenses,
  viewOnly,
  onAllocatedLicenseSelect,
}: AllocateLicenseListProps) => {
  return (
    <div className="node-licenses__list">
      <Collapse defaultActiveKey={[PlethoraApp.CT]}>
        <Panel
          header={<FormattedMessage id="nodes.node.license.ctLicenses" />}
          key={PlethoraApp.CT}
        >
          {licenses &&
            licenses
              .filter(
                (license) =>
                  license.id !== 0 && license.product === PlethoraApp.CT
              )
              .map((license) => (
                <LicenseItem
                  key={license.id}
                  license={license}
                  allocateMode={true}
                  selected={
                    selectedLicenses && selectedLicenses.includes(license.id)
                  }
                  onSelect={onAllocatedLicenseSelect}
                  viewOnly={viewOnly}
                />
              ))}
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={[PlethoraApp.SCIENCE]}>
        <Panel
          header={<FormattedMessage id="nodes.node.license.scienceLicenses" />}
          key={PlethoraApp.SCIENCE}
        >
          {licenses &&
            licenses
              .filter(
                (license) =>
                  license.id !== 0 && license.product === PlethoraApp.SCIENCE
              )
              .map((license) => (
                <LicenseItem
                  key={license.id}
                  license={license}
                  allocateMode={true}
                  selected={
                    selectedLicenses && selectedLicenses.includes(license.id)
                  }
                  onSelect={onAllocatedLicenseSelect}
                  viewOnly={viewOnly}
                />
              ))}
        </Panel>
      </Collapse>
    </div>
  );
};

export default AllocateLicenseList;

interface AllocateLicenseListProps {
  licenses: License[] | undefined;
  selectedLicenses: number[] | undefined;
  viewOnly: boolean;
  onAllocatedLicenseSelect: (
    licenseId: number,
    selected: boolean | undefined
  ) => void;
}
