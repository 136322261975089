import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

const DateWithLabel = ({ date, label }: DateWithLabelProps) => {
  return (
    <div>
      <FormattedMessage id={label} />:
      <FormattedDate
        value={date}
        day="2-digit"
        month="2-digit"
        year="numeric"
      />
    </div>
  );
};

export default DateWithLabel;

interface DateWithLabelProps {
  date: Date;
  label: string;
}
