import React from "react";
import { ActiveNodeData } from "./licenses.defenitions";
import "./LicenseTableActiveNodes.scss";
import { FormattedMessage } from "react-intl";
import { nodeIcon } from "../node-tree/tree-item/WrappedTreeItem";

const LicenseTableActiveNodes = ({
  activeNodes,
}: LicenseTableActiveNodesProps) => {
  return activeNodes.length ? (
    <div className="license-active-nodes">
      <div className="license-active-nodes__no-hover">
        <FormattedMessage
          id="licenses.activeNodes"
          values={{ count: activeNodes.length }}
        />
      </div>
      <div className="license-active-nodes__hover">
        {activeNodes.map((nodeData) => (
          <div key={nodeData.nodeId} className="license-active-nodes__item">
            <div className="license-active-nodes__item--icon">
              {nodeIcon(nodeData.nodeType)}
            </div>
            <div className="license-active-nodes__item--name">
              {nodeData.nodeId} {nodeData.nodeName}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="license-active-nodes">
      <FormattedMessage id="licenses.noActiveNodes" />
    </div>
  );
};

export default LicenseTableActiveNodes;

interface LicenseTableActiveNodesProps {
  activeNodes: ActiveNodeData[];
}
