import { CountryCode } from "src/countries.defenitions";
import {
  NodeLicenseChangeableData,
  NodeLicenseData,
} from "../licenses/licenses.defenitions";
import { UserRole } from "src/general.defenitions";

export interface BackendNodeRecord {
  id: number;
  parentNodeId: number | null;
  externalId?: string | number | null;
  name: string;
  type: NodeType;
  data: any;
  codes: string[];
}

export interface PatNodeRecordInfo {
  licenses: NodeLicenseData;
  users: UserManagementRecord[];
}

export interface UserManagementRecord extends SimpleUser {
  nodeId: number;
  role: UserRole;
  password?: string;
  expirationDate: Date | null;
}

export interface SimpleUser {
  id: number;
  externalId: string | null;
  username: string;
  data: UserData;
}

export interface UserData {
  age: number;
  fullName: string;
  firstName: string;
  lastName: string;
  language: CountryCode;
  avatarUrl: string;
}

export interface NodeRecord
  extends BackendNodeRecord,
    Partial<NodeLicenseChangeableData> {
  key: string;
  isLeaf: boolean;
  title: string;
  newNode?: boolean;
}

export interface NodeRecordAddedResponse {
  insertId: number;
  codes: string[];
}

export interface NodeRecordReplaceRequest extends NodeRecordAddedResponse {
  name: string;
  externalId?: string | number | null;
}

export enum NodeType {
  GROUP = "group",
  SCHOOL = "school",
  YEAR = "year",
  GRADE = "grade",
  CLASS = "class",
}

export enum CodeType {
  ADMIN = "A",
  TEACHER = "T",
  STUDENT = "S",
}
