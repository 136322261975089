import { Action, createSlice, EntityId, PayloadAction } from "@reduxjs/toolkit";
import { UserRoleRecord, UserToken } from "../general.defenitions";
import jwtDecode from "jwt-decode";
import { updateToken } from "./persistent.slice";
import { authenticateWithPassword } from "src/features/auth/auth.api";

const initialState: UserSlice = {
  id: null,
  email: null,
  username: null,
  roles: [],
  authError: null,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserInfo(slice: UserSlice, action: PayloadAction<UserToken | null>) {
      if (action.payload) {
        const { username, roles, id } = action.payload;
        slice.id = id;
        slice.username = username;
        slice.roles = roles;
        slice.authError = null;
      } else {
        slice.id = null;
        slice.email = null;
        slice.username = null;
        slice.roles = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      authenticateWithPassword.pending,
      (userSlice: UserSlice) => {
        userSlice.id = null;
        userSlice.email = null;
        userSlice.username = null;
        userSlice.roles = [];
        userSlice.authError = null;
      }
    );

    builder.addCase(
      authenticateWithPassword.fulfilled,
      (userSlice: UserSlice, action: PayloadAction<string>) => {
        const userToken = jwtDecode(action.payload) as UserToken;
        const { username, roles, id } = userToken;
        userSlice.id = id;
        userSlice.username = username;
        userSlice.roles = roles;
      }
    );

    builder.addCase(
      authenticateWithPassword.rejected,
      (userSlice: UserSlice, action: PayloadAction<any>) => {
        userSlice.id = null;
        userSlice.email = null;
        userSlice.username = null;
        userSlice.roles = [];
        userSlice.authError = "general.login.invalidCredentials";
      }
    );

    builder.addCase(updateToken.type, (userSlice: UserSlice, action) => {
      // TODO: that is unfortunate result of npm update, I need to fix it
      const token = (action as any).payload as string;
      if (token) {
        const decodedToken: UserToken = jwtDecode(token);
        const { username, roles, id } = decodedToken;
        userSlice.id = id;
        userSlice.username = username;
        userSlice.roles = roles;
        userSlice.authError = null;
      } else {
        userSlice.id = null;
        userSlice.email = null;
        userSlice.username = null;
        userSlice.roles = [];
      }
    });
  },
});

export const { setUserInfo } = userSlice.actions;
export default userSlice.reducer;

interface UserSlice {
  id: EntityId | null;
  email: string | null;
  username: string | null;
  roles: UserRoleRecord[];
  authError: string | null;
}
