import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CodeType } from "../../node-tree.defenitions";

const DisplayCode = ({ code }: DisplayCodeProps) => {
  const [codeType, setCodeType] = useState<CodeType | undefined>();

  useEffect(() => {
    const codeType = code[0];
    if (Object.values(CodeType).includes(codeType as CodeType)) {
      // that's a valid code!
      setCodeType(codeType as CodeType);
    }
  }, [code]);

  return (
    <div className="display-code">
      {codeType === CodeType.ADMIN && (
        <FormattedMessage id="nodes.code.admin" />
      )}
      {codeType === CodeType.TEACHER && (
        <FormattedMessage id="nodes.code.teacher" />
      )}
      {codeType === CodeType.STUDENT && (
        <FormattedMessage id="nodes.code.student" />
      )}
      : &nbsp;
      {codeType && ":" && code}
    </div>
  );
};

export default DisplayCode;

interface DisplayCodeProps {
  code: string;
}
