import {
  License,
  NodeLicenseChangeableData,
} from "src/features/licenses/licenses.defenitions";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { PlethoraApp } from "src/general.defenitions";
import NodeLicenseAppGroup from "./NodeLicenseAppGroup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { Collapse } from "antd";
import { setPendingChanges } from "../../node-tree-records.slice";
import "./NodeLicenses.scss";

const { Panel } = Collapse;

const NodeLicenseList = ({
  mergedLicenses,
  nodeLicensesIds,
  licenses,
  viewOnly,
  ctSelectedLicenseId,
  scienceSelectedLicenseId,
}: NodeLicenseListProps) => {
  const [scienceLicenses, setScienceLicenses] = useState<License[]>([]);
  const [ctLicenses, setCtLicenses] = useState<License[]>([]);

  const currentNodeLicenseInfo = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeInfo?.licenses
  );

  useEffect(() => {
    if (licenses && nodeLicensesIds) {
      const scienceLicenses = licenses.filter(
        (license) =>
          (license.product === PlethoraApp.SCIENCE ||
            license.product === null) &&
          nodeLicensesIds.includes(license.id)
      );
      setScienceLicenses(scienceLicenses);

      const ctLicenses = licenses.filter(
        (license) =>
          (license.product === PlethoraApp.CT || license.product === null) &&
          nodeLicensesIds.includes(license.id)
      );
      setCtLicenses(ctLicenses);
    } else {
      setScienceLicenses([]);
    }
  }, [nodeLicensesIds, licenses]);
  return (
    <div className="node-licenses__list">
      <Collapse defaultActiveKey={[PlethoraApp.CT]}>
        <Panel
          header={<FormattedMessage id="nodes.node.license.ctLicenses" />}
          key={PlethoraApp.CT}
        >
          <NodeLicenseAppGroup
            product={PlethoraApp.CT}
            mergedLicenses={mergedLicenses}
            licenses={ctLicenses}
            selectedLicenseId={ctSelectedLicenseId}
            assumedLicense={currentNodeLicenseInfo?.ctAssumedLicense}
            viewOnly={viewOnly}
          />
        </Panel>
      </Collapse>
      <Collapse defaultActiveKey={[PlethoraApp.SCIENCE]}>
        <Panel
          header={<FormattedMessage id="nodes.node.license.scienceLicenses" />}
          key={PlethoraApp.SCIENCE}
        >
          <NodeLicenseAppGroup
            product={PlethoraApp.SCIENCE}
            mergedLicenses={mergedLicenses}
            licenses={scienceLicenses}
            selectedLicenseId={scienceSelectedLicenseId}
            assumedLicense={currentNodeLicenseInfo?.scienceAssumedLicense}
            viewOnly={viewOnly}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default NodeLicenseList;

interface NodeLicenseListProps {
  mergedLicenses: NodeLicenseChangeableData | undefined;
  ctSelectedLicenseId: number | null | undefined;
  scienceSelectedLicenseId: number | null | undefined;
  nodeLicensesIds: number[] | undefined;
  licenses: License[] | undefined;
  viewOnly: boolean;
}
