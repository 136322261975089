import React from "react";
import HeaderLeft from "./components/HeaderLeft";
import HeaderRight from "./components/HeaderRight";
import "./PrimaryHeader.scss";

const PrimaryHeader = () => {
  return (
    <div className="primary-header">
      <div className="primary-header__left">
        <HeaderLeft />
      </div>
      <div className="primary-header__right">
        <HeaderRight />
      </div>
    </div>
  );
};

export default React.memo(PrimaryHeader);
