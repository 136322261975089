import {
  EntityState,
  PayloadAction,
  Update,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import {
  AllLicensesResponse,
  ContentPackage,
  License,
  LicenseFormItem,
  SaveLisensesResponse,
} from "./licenses.defenitions";
import { PlethoraApp } from "src/general.defenitions";
import { getAllLicensesApi } from "./licenses.api";
import { RootState } from "src/store";
import moment from "moment";
import { saveLicenses } from "./licenses.actions";

export const licensesAdapter = createEntityAdapter<License>({
  sortComparer: (a, b) => a.id - b.id,
});
export const licensesSelectors = licensesAdapter.getSelectors();

const licensesSlice = createSlice({
  name: "licenses",
  initialState: licensesAdapter.getInitialState<LicenseSliceInitialState>({
    selectedApp: PlethoraApp.ALL,
    scienceContentPackages: [],
    licenseNameFilter: "",
    selectedLicenses: [],
  }),
  reducers: {
    setSelectedApp(slice: LicenseSlice, action: PayloadAction<PlethoraApp>) {
      console.log("selected app: ", action.payload);
      slice.selectedApp = action.payload;
    },
    setLicenseNameFilter(slice: LicenseSlice, action: PayloadAction<string>) {
      slice.licenseNameFilter = action.payload;
    },
    updateManyLicenses(slice: LicenseSlice, action: PayloadAction<License[]>) {
      const updates: Update<License>[] = action.payload.map((license) => ({
        id: license.id,
        changes: license,
      }));
      licensesAdapter.updateMany(slice, updates);
    },

    setSelectedLicenses(slice: LicenseSlice, action: PayloadAction<string[]>) {
      slice.selectedLicenses = action.payload;
    },

    deleteSelectedLicenses(slice: LicenseSlice) {
      licensesAdapter.removeMany(slice, slice.selectedLicenses);
      slice.selectedLicenses = [];
    },

    deleteLicenseById(slice: LicenseSlice, action: PayloadAction<string>) {
      licensesAdapter.removeOne(slice, action.payload);
    },
    addLicense(slice: LicenseSlice, action: PayloadAction<License>) {
      licensesAdapter.addOne(slice, action.payload);
    },

    createPendingLicense(slice: LicenseSlice) {
      const minLicenseId = Math.min(...(slice.ids as number[]));

      const pendingLicense: License = {
        id: minLicenseId - 1,
        name: "",
        product: null,
        expirationDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        period: null,
        maxUsers: 0,
        currentUsers: 0,
        data: { contentPackage: null },
      };
      licensesAdapter.addOne(slice, pendingLicense);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllLicensesApi.fulfilled,
      (slice: LicenseSlice, action: PayloadAction<AllLicensesResponse>) => {
        licensesAdapter.setAll(slice, action.payload.licenses);
        slice.scienceContentPackages = action.payload.scienceContentPackages;
      }
    );

    builder.addCase(
      saveLicenses.fulfilled,
      (slice: LicenseSlice, action: PayloadAction<SaveLisensesResponse>) => {
        const { pendingLicenses, addedIds } = action.payload;
        // update licenses
        const updatedLicenses = pendingLicenses.filter(
          (license) => license.id > 0
        );
        const updates: Update<License>[] = updatedLicenses.map((license) => ({
          id: license.id,
          changes: license,
        }));

        licensesAdapter.updateMany(slice, updates);

        //replace the newly created licenses in the state based on their new ids

        for (const pendingLicenseId in addedIds) {
          const insertedLicense = pendingLicenses.find(
            (license) => license.id === parseInt(pendingLicenseId)
          );
          const insertedLicenseId = addedIds[pendingLicenseId];

          if (insertedLicense) {
            licensesAdapter.removeOne(slice, pendingLicenseId);
            licensesAdapter.addOne(slice, {
              ...insertedLicense,
              id: insertedLicenseId,
            });
          }
        }
      }
    );
  },
});

interface LicenseSliceInitialState {
  selectedApp: PlethoraApp | null;
  scienceContentPackages: ContentPackage[];
  licenseNameFilter: string;
  selectedLicenses: string[];
}

type LicenseSlice = LicenseSliceInitialState & EntityState<License>;

const selectAllLicenses = (state: RootState) =>
  licensesSelectors.selectAll(state.sysAdminLicenses) || [];

const licenseNameFilter = (state: RootState) =>
  state.sysAdminLicenses.licenseNameFilter;

const selectSelectedApp = (state: RootState) =>
  state.sysAdminLicenses.selectedApp;

export const selectFilteredLicenses = createSelector(
  // Input selectors
  selectAllLicenses, // Use the existing selector to get all licenses
  licenseNameFilter,
  selectSelectedApp,
  // The result function
  (licenses, licenseNameFilter, selectedApp): License[] => {
    return licenses
      .filter((license) =>
        selectedApp === PlethoraApp.ALL ? true : license.product === selectedApp
      )
      .filter((license) =>
        licenseNameFilter.length > 0
          ? license.name
              .toLocaleLowerCase()
              .includes(licenseNameFilter.toLocaleLowerCase())
          : true
      );
  }
);

export const {
  setSelectedApp,
  setLicenseNameFilter,
  setSelectedLicenses,
  createPendingLicense,
  deleteLicenseById,
  addLicense,
  updateManyLicenses,
  deleteSelectedLicenses,
} = licensesSlice.actions;

export default licensesSlice.reducer;

export const licenseFormItemToLicense = (
  formItem: LicenseFormItem,
  contentPackage: ContentPackage | null
): License => {
  return {
    id: formItem.id,
    name: formItem.name,
    product: formItem.product,
    expirationDate: formItem.expirationDate,
    period: formItem.period,
    maxUsers: formItem.maxUsers,
    currentUsers: formItem.currentUsers,
    data: {
      contentPackage,
    },
  };
};
