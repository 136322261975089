import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthenticateWithPasswordRequest } from "src/general.defenitions";
import { RootState } from "src/store";
import { apiCall, apiUrls } from "src/store/api";

export const authenticateWithPassword = createAsyncThunk<
  any,
  AuthenticateWithPasswordRequest,
  { state: RootState }
>("authenticateWithPassword", async (loginData) => {
  const response = await apiCall({
    method: "POST",
    url: apiUrls.authenticateWithPassword,
    data: loginData,
  });

  return response.data;
});
