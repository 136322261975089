import { Button, ButtonProps } from "antd";
import React from "react";
import "./PrimaryButton.scss";
import cn from "classnames";

const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <Button
      {...{ ...props, noborder: undefined, cta: undefined }}
      className={cn({
        "styled-primary-button": true,
        "styled-primary-button__no-border": props.noborder,
        "styled-primary-button__gradient": props.cta,
        "styled-primary-button__disabled": props.disabled,
        [props.className ? props.className : ""]: true,
      })}
    >
      {props.children}
    </Button>
  );
};

export default React.memo(PrimaryButton);

interface PrimaryButtonProps extends ButtonProps {
  noborder?: boolean;
  cta?: boolean;
}
