import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import {
  selectRootNodesIds,
  setExpandedNodes,
} from "./node-tree-records.slice";
import { getNodeInfo, getNodeTree } from "./node-tree.api";
import WrappedTreeNode from "./tree-item/WrappedTreeItem";
import TreeView from "@mui/lab/TreeView";
import PrimaryButton from "src/ui/buttons/PrimaryButton";
import { FolderOpenOutlined, FolderOutlined } from "@ant-design/icons";

const RootNodes = () => {
  const [treeLoading, setTreeLoading] = useState(false);

  const rootNodesIds = useSelector((state: RootState) =>
    selectRootNodesIds(state.nodeRecords)
  );

  const selectedNodeId = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeId
  );

  const expandedNodes = useSelector(
    (state: RootState) => state.nodeRecords.expandedNodes
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setTreeLoading(true);
    dispatch(getNodeTree()).then(() => {
      setTreeLoading(false);
    });
  }, [dispatch]);

  const onNodeSelect = (event: React.SyntheticEvent, nodeId: string) => {
    const isSelect = (event.target as HTMLDivElement).classList[0]?.startsWith(
      "tree-item-label"
    );
    if (isSelect) {
      dispatch(getNodeInfo(parseInt(nodeId)));
    }
  };

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    const isExpand = !(event.target as HTMLDivElement).classList[0]?.startsWith(
      "tree-item-label"
    );
    if (isExpand) {
      dispatch({ type: setExpandedNodes.type, payload: nodeIds });
    }
  };

  return (
    <div>
      <Search
        style={{ marginBottom: 8 }}
        placeholder="Search"
        // onChange={onChange}
      />
      <TreeView
        selected={`${selectedNodeId}` || ""}
        expanded={expandedNodes}
        onNodeSelect={onNodeSelect}
        onNodeToggle={handleToggle}
        defaultCollapseIcon={
          <PrimaryButton
            id="toggle"
            type="link"
            size="small"
            icon={<FolderOpenOutlined />}
          />
        }
        defaultExpandIcon={
          <PrimaryButton
            id="toggle"
            type="link"
            size="small"
            icon={<FolderOutlined />}
          />
        }
      >
        {rootNodesIds.map((id) => (
          <WrappedTreeNode key={id} nodeId={id} />
        ))}
      </TreeView>
    </div>
  );
};

export default RootNodes;
