import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LanguageSwitcher from "./LanguageSwitcher";
import "./HeaderRight.scss";
import ChevronDown from "../../../ui/icons/ChevronDown";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import { updateToken } from "src/store/persistent.slice";

const HeaderRight = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const userName = useSelector((state: RootState) => state.user.username);

  const onUserMenuClick: MenuProps["onClick"] = (event) => {
    if (event.key === "logout") {
      // to logout we need to remove token
      // clear user
      // redirect to login
      dispatch({ type: updateToken.type, payload: null });
      navigate("/login");
    }
  };

  const userMenuItems = [
    { label: formatMessage({ id: "general.profile" }), key: "profile" },
    { label: formatMessage({ id: "general.logout" }), key: "logout" },
  ];

  return (
    <div className="right-panel">
      <Dropdown menu={{ items: userMenuItems, onClick: onUserMenuClick }}>
        <Button type="text" icon={<UserOutlined />}>
          {userName} <ChevronDown />
        </Button>
      </Dropdown>
      <LanguageSwitcher />
    </div>
  );
};

export default HeaderRight;
