import { Form, Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useDispatch, useSelector } from "react-redux";
import { AuthenticateWithPasswordRequest } from "../../general.defenitions";
import { AppDispatch, RootState } from "../../store";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../ui/buttons/PrimaryButton";
import "./Login.scss";
import { authenticateWithPassword } from "./auth.api";

const Login = () => {
  const [form] = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const authError = useSelector((state: RootState) => state.user.authError);
  const persistentSlice = useSelector(
    (state: RootState) => state.persistentUser
  );

  const onLogin = useCallback(
    async (loginData: AuthenticateWithPasswordRequest) => {
      setIsLoading(true);
      dispatch(authenticateWithPassword(loginData));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      persistentSlice.token &&
      persistentSlice.tokenExpires &&
      persistentSlice.tokenExpires > Math.floor(Date.now() / 1000)
    ) {
      // if there's already a valid token we should redirect a user to
      // the main page
      setIsLoading(false);
      navigate("/node-tree");
    }
  }, [navigate, persistentSlice.token, persistentSlice.tokenExpires]);

  useEffect(() => {
    if (authError) {
      setIsLoading(false);
    }
  }, [authError]);

  return (
    <div className="login__backdrop">
      <div className="login__form-wrapper">
        {isLoading && (
          <div className="login__loading">
            <LoadingOutlined />
          </div>
        )}
        <Form form={form} onFinish={onLogin} className="login__form">
          <Form.Item name="username" rules={[{ required: true }]}>
            <Input
              type="text"
              placeholder={formatMessage({ id: "general.login.username" })}
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          {authError && (
            <div className="login__form--error-message">
              <FormattedMessage id={authError} />
            </div>
          )}
          <Form.Item>
            <PrimaryButton type="primary" htmlType="submit">
              <FormattedMessage id="general.login.submit" />
            </PrimaryButton>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
