import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  AllLicensesResponse,
  License,
  LicenseUpsertResponse,
} from "./licenses.defenitions";
import { RootState } from "src/store";
import { apiCall, apiUrls } from "src/store/api";

export const getAllLicensesApi = createAsyncThunk<
  AllLicensesResponse,
  undefined,
  { state: RootState }
>("getAllLicenses", async (param, { getState, dispatch }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "GET",
    url: apiUrls.licenses,
    token,
  });
  return response.data;
});

export const putLicensesUpdateApi = createAsyncThunk<
  LicenseUpsertResponse,
  License[],
  { state: RootState }
>("putLicensesUpdate", async (licenses, { getState, dispatch }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "PUT",
    url: apiUrls.licenses,
    data: licenses,
    token,
  });

  return response.data as LicenseUpsertResponse;
});

export const deleteLicensesApi = createAsyncThunk<
  { deleted: boolean },
  number[],
  { state: RootState }
>("deleteLicensees", async (licenseIds, { getState, dispatch }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "DELETE",
    url: apiUrls.licenses,
    data: licenseIds,
    token,
  });
  return response.data;
});
