import React from "react";
import "./TreeItemLabel.scss";

const TreeItemLabel = ({ name, actions }: TreeItemProps) => {
  return (
    <div className="tree-item-label">
      <div className="tree-item-label__name">{name}</div>
      <div className="tree-item-label__actions">{actions}</div>
    </div>
  );
};

export default TreeItemLabel;

interface TreeItemProps {
  name: string;
  actions?: React.ReactNode;
}
