import { Update, createAsyncThunk } from "@reduxjs/toolkit";
import { UserManagementRecord } from "../../node-tree.defenitions";
import { apiCall, apiUrls } from "src/store/api";
import { RootState } from "src/store";
import { deleteUser, editUser } from "../../node-item-users.slice";
import { UserRole } from "src/general.defenitions";

export const editNodeUserAction = createAsyncThunk<
  any,
  Update<UserManagementRecord> & { nodeId: number },
  { state: RootState }
>("editNodeUserAction", async (param, { getState, dispatch }) => {
  const state = getState();
  const token = state.persistentUser.token;
  const userId = param.id;
  const nodeId = param.nodeId;
  const changes = param.changes;
  const response = await apiCall({
    method: "PATCH",
    url: apiUrls.nodeUser(nodeId, userId as number),
    data: changes,
    token: token,
  });
  if (response.data) {
    dispatch({
      type: editUser.type,
      payload: {
        id: userId,
        changes,
      },
    });
  }
});

export const getUserAuthToken = createAsyncThunk<
  string,
  { nodeId: number; userId: number; targetApp: string },
  { state: RootState }
>(
  "getUserAuthToken",
  async ({ userId, nodeId, targetApp }, { getState, dispatch }) => {
    const state = getState();
    const token = state.persistentUser.token;
    // action permitted only to system admin
    const userRoles = state.user.roles;
    if (
      !userRoles
        .map((roleRecord) => roleRecord.role)
        .includes(UserRole.SYSADMIN)
    ) {
      throw new Error("User is not system admin");
    }

    const response = await apiCall({
      method: "GET",
      url: apiUrls.loginAsUser(nodeId, userId, targetApp),
      token: token,
    });
    return response.data;
  }
);

export const deleteNodeUserAction = createAsyncThunk<
  any,
  { nodeId: number; userId: number },
  { state: RootState }
>("deleteNodeUserAction", async (param, { getState, dispatch }) => {
  const state = getState();
  const token = state.persistentUser.token;
  const userId = param.userId;
  const nodeId = param.nodeId;
  const response = await apiCall({
    method: "DELETE",
    url: apiUrls.nodeUser(nodeId, userId),
    token: token,
  });
  dispatch({
    type: deleteUser.type,
    payload: {
      id: userId,
    },
  });
});
