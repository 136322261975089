import { Layout } from "antd";
import { Header } from "antd/lib/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";
import RequireAuth from "../features/auth/RequireAuth";
import TimeoutModal from "../features/auth/TimeoutModal";
import "./MainLayout.scss";
import PrimaryHeader from "./primary-header/PrimaryHeader";

const MainLayout = () => {
  return (
    <Layout className="main-layout">
      <Header>
        <PrimaryHeader />
      </Header>
      <Outlet />
      <RequireAuth />
      <TimeoutModal />
    </Layout>
  );
};

export default React.memo(MainLayout);
