import { useEffect, useState } from "react";
import { UserManagementRecord } from "../../node-tree.defenitions";
import { Button, List, Popconfirm } from "antd";
import DateWithLabel from "./DateWithLabel";
import ValueWithLabel from "./ValueWithLabel";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { deleteNodeUserAction, editNodeUserAction } from "./node-user.actions";
import { Update } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store";
import { LoadingOutlined } from "@ant-design/icons";
import "./NodeUser.scss";
import LoginAsUser from "./LoginAsUser";
import { UserRole } from "src/general.defenitions";

const NodeUser = ({ user, nodeId }: NodeUserProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [actions, setActions] = useState<JSX.Element[]>([]);
  const [password, setPassword] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const isSysAdmin = useSelector((state: RootState) =>
    state.user.roles
      .map((roleRecord) => roleRecord.role)
      .includes(UserRole.SYSADMIN)
  );

  useEffect(() => {
    user.password && setPassword(user.password);
  }, [user.password]);

  useEffect(() => {
    const actions: JSX.Element[] = [];

    if (isSysAdmin) {
      actions.push(<LoginAsUser user={user} nodeId={nodeId} key="loginAs" />);
    }

    if (edit) {
      actions.push(
        <Button type="text" key="save" disabled={loader} onClick={onSave}>
          {loader ? (
            <LoadingOutlined />
          ) : (
            <FormattedMessage id="user.saveUser" />
          )}
        </Button>,
        <Button type="text" key="save" onClick={onBack}>
          <FormattedMessage id="general.back" />
        </Button>
      );
    } else {
      actions.push(
        <Button
          type="text"
          disabled={!!user.externalId || loader}
          key="edit"
          onClick={onEdit}
        >
          <FormattedMessage id="user.editUser" />
        </Button>,
        <Popconfirm
          title={<FormattedMessage id="user.deleteUserMessage" />}
          onConfirm={onDelete}
          okText="Yes"
          cancelText="No"
          className="node-user__delete-popconfirm"
        >
          <Button type="text" key="delete" disabled={loader}>
            {loader ? (
              <LoadingOutlined />
            ) : (
              <FormattedMessage id="user.deleteUser" />
            )}
          </Button>
        </Popconfirm>
      );
    }

    setActions(actions);
  }, [edit, isSysAdmin]);

  const onEdit = () => {
    setEdit(true);
  };

  const onBack = () => {
    setEdit(false);
  };

  const onPasswordChange = (newPassword: string) => {
    setPassword(newPassword);
  };

  const onSave = () => {
    const updateObject: Update<UserManagementRecord> & { nodeId: number } = {
      id: user.id,
      nodeId,
      changes: {
        password,
      },
    };
    setLoader(true);
    dispatch(editNodeUserAction(updateObject))
      .unwrap()
      .then(() => {
        setLoader(false);
        setEdit(false);
      });
  };

  const onDelete = () => {
    setLoader(true);
    dispatch(deleteNodeUserAction({ nodeId, userId: user.id })).then(() => {
      setLoader(false);
    });
  };

  return (
    <List.Item actions={actions}>
      <List.Item.Meta
        title={
          <>
            <div className="node-users__description">
              <ValueWithLabel
                value={
                  user.data.fullName ||
                  user.data.firstName + " " + user.data.lastName
                }
                width={150}
              />
              {user.id && (
                <ValueWithLabel label="user.id" value={user.id} width={75} />
              )}
              {user.externalId && (
                <ValueWithLabel
                  label="user.externalId"
                  value={user.externalId}
                  width={150}
                />
              )}
              {user.expirationDate && (
                <DateWithLabel
                  date={user.expirationDate}
                  label="user.expirationDate"
                />
              )}
              {user.username && (
                <ValueWithLabel
                  label="general.login.username"
                  value={user.username}
                  width={225}
                />
              )}
              {user.password && (
                <ValueWithLabel
                  label="general.login.password"
                  value={password}
                  edit={edit}
                  onValueChange={onPasswordChange}
                  valueHidden
                  width={150}
                />
              )}
            </div>
          </>
        }
      />
    </List.Item>
  );
};

export default NodeUser;

export interface NodeUserProps {
  user: UserManagementRecord;
  nodeId: number;
}
