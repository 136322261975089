import { WarningOutlined } from "@ant-design/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import "./NewNodeNotice.scss";

const NewNodeNotice = () => {
  return (
    <div className="new-node-notice">
      <WarningOutlined /> <FormattedMessage id="nodes.newNodeNotice" />
    </div>
  );
};

export default NewNodeNotice;
