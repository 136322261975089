import { Button, Input, Segmented, Tag } from "antd";
import "./LicenseTableActions.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  createPendingLicense,
  setLicenseNameFilter,
  setSelectedApp,
} from "./licenses.slice";
import { PlethoraApp } from "src/general.defenitions";
import { SegmentedValue } from "antd/lib/segmented";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { AppDispatch, RootState } from "src/store";
import { deleteLicenses, saveLicenses } from "./licenses.actions";
import { useState } from "react";

const LicenseTableActions = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<AppDispatch>();

  const [saveLoader, setSaveLoader] = useState(false);

  const selectedLicenses = useSelector(
    (state: RootState) => state.sysAdminLicenses.selectedLicenses
  );

  const pendingLicenses = useSelector(
    (state: RootState) => !!state.unsavedLicensesSlice.ids.length
  );

  const onNameFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    dispatch({ type: setLicenseNameFilter.type, payload: value });
  };

  const onProductFilterChange = (value: SegmentedValue) => {
    dispatch({ type: setSelectedApp.type, payload: value });
  };

  const onAddLicenseClick = () => {
    dispatch({ type: createPendingLicense.type });
  };

  const onSaveLicenseClick = () => {
    setSaveLoader(true);
    dispatch(saveLicenses()).then(() => {
      setSaveLoader(false);
    });
  };

  const onDeleteSelectedClick = () => {
    dispatch(deleteLicenses());
  };

  return (
    <div className="table-actions">
      <div>
        <Button
          type="primary"
          icon={<DeleteOutlined />}
          disabled={selectedLicenses.length === 0}
          onClick={onDeleteSelectedClick}
        >
          <FormattedMessage id="licenses.deleteLicenses" />
        </Button>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={onAddLicenseClick}
        >
          <FormattedMessage id="licenses.addLicense" />
        </Button>
        <Button
          type="primary"
          disabled={!pendingLicenses || saveLoader}
          icon={saveLoader ? <LoadingOutlined /> : <SaveOutlined />}
          onClick={onSaveLicenseClick}
        >
          <FormattedMessage id="licenses.saveLicenses" />
        </Button>
      </div>
      <div>
        <div className="table-actions__name-filter">
          <Input
            type="text"
            placeholder={formatMessage({ id: "licenses.filterName" })}
            onChange={onNameFilterChange}
          />
        </div>
        <div className="table-actions__product-filter">
          <FormattedMessage id="licenses.filterPlethoraApp" />:
          <Segmented
            onChange={onProductFilterChange}
            options={[
              {
                label: <b>{PlethoraApp.ALL}</b>,
                value: PlethoraApp.ALL,
              },
              {
                label: <b style={{ color: "#dd4401" }}>{PlethoraApp.CT}</b>,
                value: PlethoraApp.CT,
              },
              {
                label: (
                  <b style={{ color: "#5d4fff" }}>{PlethoraApp.SCIENCE}</b>
                ),
                value: PlethoraApp.SCIENCE,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default LicenseTableActions;
