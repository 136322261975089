import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./features/auth/Login";
import NodeTreeView from "./features/node-tree/NodeTreeView";
import english from "./lang/en.json";
import hebrew from "./lang/he.json";
import MainLayout from "./layout/MainLayout";
import { RootState } from "./store";
import { setUserInfo } from "./store/user.slice";
import { UserToken } from "./general.defenitions";
import jwtDecode from "jwt-decode";
import LicensesView from "./features/licenses/LicensesView";

const MainRouter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [messages, setMessages] = useState<Record<string, string>>(english);
  const [rtl, setRtl] = useState(false);

  const currentLanguage = useSelector(
    (state: RootState) => state.persistentUser.language
  );

  const token = useSelector((state: RootState) => state.persistentUser.token);

  useEffect(() => {
    let messages: Record<string, string> = english;
    let rtl = false;
    if (currentLanguage === "he") {
      messages = hebrew;
      rtl = true;
    }
    setMessages(messages);
    setRtl(rtl);
  }, [currentLanguage]);

  useEffect(() => {
    if (!token && window.location.pathname !== "/login") {
      navigate("/login");
    }
    if (token) {
      const decodedToken: UserToken = jwtDecode(token);
      console.log(decodedToken);
      if (decodedToken.expires > Math.floor(Date.now() / 1000)) {
        // token is still valid!

        dispatch({ type: setUserInfo.type, payload: decodedToken });

        // if user is on login route we should redirect him to main page
        if (location.pathname === "/login" || location.pathname === "/") {
          navigate("/node-tree");
        }
      } else {
        // token is expired!
        dispatch({ type: setUserInfo.type, payload: null });
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  }, [token]);

  return (
    <div className={rtl ? "rtl" : ""}>
      <IntlProvider locale={currentLanguage} messages={messages}>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/*" element={<MainLayout />}>
            <Route path="node-tree" element={<NodeTreeView />} />
            <Route path="licenses" element={<LicensesView />} />
          </Route>
        </Routes>
      </IntlProvider>
    </div>
  );
};

export default MainRouter;
