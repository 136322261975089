import { EntityState, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { licenseFormItemToLicense, licensesAdapter } from "./licenses.slice";
import {
  ContentPackage,
  License,
  LicenseFormItem,
} from "./licenses.defenitions";
import { saveLicenses } from "./licenses.actions";

const unsavedLicensesSlice = createSlice({
  name: "unsavedLicenses",
  initialState: licensesAdapter.getInitialState(),
  reducers: {
    updateLicense(
      slice: EntityState<License>,
      action: PayloadAction<{
        pendingLicense: LicenseFormItem;
        contentPackage: ContentPackage | null;
      }>
    ) {
      // we should check if the license is already in the slice
      // if it is, we should update it
      // if it isn't, we should add it
      const { pendingLicense, contentPackage } = action.payload;
      const license: License = licenseFormItemToLicense(
        pendingLicense,
        contentPackage
      );
      const licenseIndex = slice.ids.indexOf(pendingLicense.id);
      if (licenseIndex === -1) {
        licensesAdapter.addOne(slice, license);
      } else {
        licensesAdapter.updateOne(slice, {
          id: pendingLicense.id,
          changes: license,
        });
      }
    },
    clearPendingLicense(
      slice: EntityState<License>,
      action: PayloadAction<number>
    ) {
      licensesAdapter.removeOne(slice, action.payload);
    },
    clearPendingLicenses(slice: EntityState<License>) {},
  },
  extraReducers: (builder) => {
    builder.addCase(saveLicenses.fulfilled, (slice) => {
      licensesAdapter.removeAll(slice);
    });
  },
});

export const { clearPendingLicenses, updateLicense, clearPendingLicense } =
  unsavedLicensesSlice.actions;

export default unsavedLicensesSlice.reducer;
