import React from "react";
import { nodeRecordUsersSelectors } from "../../node-item-users.slice";
import { RootState } from "src/store";
import { useSelector } from "react-redux";
import { Collapse, List } from "antd";
import { UserRole } from "src/general.defenitions";
import { FormattedMessage } from "react-intl";
import "./NodeUsers.scss";
import NodeUser from "./NodeUser";

const NodeUsers = () => {
  const nodeUsers = useSelector((state: RootState) =>
    nodeRecordUsersSelectors.selectAll(state.nodeRecordUsers)
  );

  const selectedNodeId = useSelector(
    (state: RootState) => state.nodeRecords.selectedNodeId
  );

  return (
    <div className="node-users">
      <Collapse defaultActiveKey={["1"]}>
        <Collapse.Panel
          header={<FormattedMessage id="user.roles.students" />}
          key="1"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={nodeUsers.filter(
              (user) => user.role === UserRole.STUDENT
            )}
            renderItem={(user) => (
              <NodeUser user={user} nodeId={selectedNodeId as number} />
            )}
          />
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={["2"]}>
        <Collapse.Panel
          header={<FormattedMessage id="user.roles.teachers" />}
          key="2"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={nodeUsers.filter(
              (user) => user.role === UserRole.TEACHER
            )}
            renderItem={(user) => (
              <NodeUser user={user} nodeId={selectedNodeId as number} />
            )}
          />
        </Collapse.Panel>
      </Collapse>
      <Collapse defaultActiveKey={["3"]}>
        <Collapse.Panel
          header={<FormattedMessage id="user.roles.admins" />}
          key="3"
        >
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={nodeUsers.filter(
              (user) => user.role === UserRole.ADMIN
            )}
            renderItem={(user) => (
              <NodeUser user={user} nodeId={selectedNodeId as number} />
            )}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default NodeUsers;
