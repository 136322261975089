import { Menu } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UserRole, UserRoleRecord } from "src/general.defenitions";
import { RootState } from "src/store";
import "./HeaderLeft.scss";

const HeaderLeft = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [currentItem, setCurrentItem] = useState<string[]>(["lessons"]);

  const userRoles = useSelector(
    (state: RootState) => state.user.roles
  ) as UserRoleRecord[];

  const userMenuItems: ItemType[] = useMemo(
    () => [
      {
        key: "node-tree",
        label: <FormattedMessage id="general.nodeTree" />,
      },
    ],
    []
  );

  const sysAdminMenuItems: ItemType[] = useMemo(
    () => [
      {
        key: "node-tree",
        label: <FormattedMessage id="general.nodeTree" />,
      },
      {
        key: "licenses",
        label: <FormattedMessage id="nodes.node.tabs.licenses" />,
      },
    ],
    []
  );

  const defaultSelectedKeys: string[] = useMemo(() => ["node-tree"], []);

  useEffect(() => {
    const components = pathname.split("/");
    const section = components[1];
    //const id = components[2]

    setCurrentItem([section]);
  }, [pathname]);

  const onMenuClick = useCallback(({ key }: { key: string }) => {
    navigate(`/${key}`);
  }, []);

  return (
    <div className="left-header">
      <div className="left-header__logo">
        <img
          src={`${process.env.PUBLIC_URL}/plethora-logo@x3.png`}
          alt="Plethora Science logo"
        />
      </div>
      <div className="left-header__menu">
        <Menu
          mode="horizontal"
          className="left-header__menu"
          defaultSelectedKeys={defaultSelectedKeys}
          onClick={onMenuClick}
          selectedKeys={currentItem}
          items={
            userRoles.map((record) => record.role).includes(UserRole.SYSADMIN)
              ? sysAdminMenuItems
              : userMenuItems
          }
        />
      </div>
    </div>
  );
};

export default React.memo(HeaderLeft);
