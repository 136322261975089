import { DatePicker, Form } from "antd";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "./EditableRow";
import { LicenseFormItem } from "./licenses.defenitions";
import { ColumnTitle } from "antd/lib/table/interface";
import "./Editable.scss";
import moment from "moment";
import { formatDate } from "src/utils";
import { DatePickRef } from "antd/lib/date-picker/generatePicker/interface";

const EditableDateCell: React.FC<EditableCellProps> = ({
  title,
  dataIndex,
  record,
  width = "auto",
  handleSave,
}) => {
  const [editing, setEditing] = useState(false);
  const datePickerRef = useRef<DatePickRef<"date">>(null);
  const form = useContext(EditableContext)!;

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const onFocus = () => {
    setEditing(true);
  };

  const save = async () => {
    setEditing(false);
    try {
      const values = await form.validateFields();
      values[dataIndex] = values[dataIndex].format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const onBlur = () => {
    setEditing(false);
  };

  return editing ? (
    <Form.Item
      style={{ margin: 0, width, minWidth: "100px" }}
      name={dataIndex}
      valuePropName={"date"}
      initialValue={moment(record[dataIndex] as unknown as string)}
    >
      <DatePicker
        onChange={save}
        open={editing}
        onBlur={onBlur}
        onFocus={onFocus}
        allowClear={false}
        autoFocus
      />
    </Form.Item>
  ) : (
    <div
      className="editable-cell-value-wrap"
      style={{ paddingRight: 24, width, minWidth: "100px" }}
      onClick={toggleEdit}
    >
      {formatDate(record[dataIndex] as unknown as string)}
    </div>
  );
};

interface EditableCellProps {
  title: ColumnTitle<LicenseFormItem>;
  children: ReactElement;
  dataIndex: keyof LicenseFormItem;
  width?: string | number;
  record: LicenseFormItem;
  handleSave: (record: LicenseFormItem) => void;
}

export default EditableDateCell;
