import { Modal } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { updateToken } from "../../store/persistent.slice";
import "./TimeoutModal.scss";

const TimeoutModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeoutModalVisible, setTimeoutModalVisible] =
    useState<boolean>(false);
  const [logoutCounter, setLogoutCounter] = useState<number>(0);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const persistentSlice = useSelector(
    (state: RootState) => state.persistentUser
  );

  useEffect(() => {
    // token lifespan left
    const secondsLeft = persistentSlice.tokenExpires
      ? persistentSlice.tokenExpires - Math.floor(Date.now() / 1000)
      : null;

    // timeout which will fire a modal window two minutes before token expires
    // if there's positive number of seconds left
    timeout.current =
      secondsLeft !== null && secondsLeft > 0
        ? setTimeout(() => {
            setTimeoutModalVisible(true);
          }, (secondsLeft - 60) * 1000)
        : null;

    return () => {
      // if there's a timeout we should clear it, since token has been updated
      timeout.current && clearTimeout(timeout.current);
    };
  }, [persistentSlice.tokenExpires]);

  document.onvisibilitychange = () => {
    if (timeout.current) {
      const secondsLeft = persistentSlice.tokenExpires
        ? persistentSlice.tokenExpires - Math.floor(Date.now() / 1000)
        : null;
      if (secondsLeft === null || secondsLeft < 0) {
        setTimeoutModalVisible(false);
        clearTimeout(timeout.current);
        navigate("/login");
      }
    }
  };

  const onLogout = useCallback(() => {
    // to logout we need to remove token
    // clear user
    // redirect to login
    dispatch({ type: updateToken.type, payload: null });
    navigate("/login");
  }, [dispatch, navigate]);

  const onCancel = useCallback(() => {
    setTimeoutModalVisible(false);
    onLogout();
  }, [onLogout]);

  const onOkay = useCallback(() => {
    // we need to perform any backend request to get the new token
    // so I will just fetch the lessons again, but it can be anything
    //dispatch(updateToken());
    setTimeoutModalVisible(false);
  }, [dispatch]);

  useEffect(() => {
    // if timeout modal gets visible we need to start the counter 60 to 0
    // until it runs out the modal is visible, and if user will not do anything
    // user will be logged out
    let interval: any = null;
    if (timeoutModalVisible) {
      let counter = 60;
      setLogoutCounter(counter);
      interval = setInterval(() => {
        counter -= 1;
        setLogoutCounter(counter);
        if (counter <= 0) {
          onLogout();
          setTimeoutModalVisible(false);
          setLogoutCounter(0);
        }
      }, 1000);
    }

    return () => {
      interval && clearInterval(interval);
    };
  }, [onLogout, timeoutModalVisible]);

  return (
    <Modal
      open={timeoutModalVisible}
      maskClosable={false}
      onCancel={onCancel}
      cancelText={<FormattedMessage id="general.logout" />}
      okText={<FormattedMessage id="general.login.extendSession" />}
      onOk={onOkay}
      className="timeout-modal"
    >
      <div className="counter-message">
        <div className="counter-message__time-left">{logoutCounter}</div>
        <div className="counter-message__message-body">
          <FormattedMessage id="general.login.extendSessionMessage" />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(TimeoutModal);
