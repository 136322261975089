import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store";
import { selectCurrentNode } from "../../node-tree-records.slice";
import NewNodeNotice from "./NewNodeNotice";
import NodeEditActions from "./NodeEditActions";
import "./NodeHeader.scss";
import NodeUnchangableData from "./NodeUnchangableData";

const NodeHeader = () => {
  const selectedNode = useSelector((state: RootState) =>
    selectCurrentNode(state)
  );

  return (
    <div className="node-header">
      {selectedNode?.newNode ? <NewNodeNotice /> : <NodeUnchangableData />}
      <NodeEditActions />
    </div>
  );
};

export default NodeHeader;
