import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  SyncOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/store";
import PrimaryButton from "src/ui/buttons/PrimaryButton";
import {
  selectChildrenByParentId,
  selectCurrentNode,
  setEditCurrentNode,
} from "../../node-tree-records.slice";
import {
  deleteNodeAction,
  editNodeAction,
  postNewNodeAction,
} from "../../node-tree.actions";
import "./NodeEditActions.scss";

const NodeEditActions = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [saving, setSaving] = useState<boolean>(false);
  const editCurrentNode = useSelector(
    (state: RootState) => state.nodeRecords.editCurrentNode
  );
  const [noPendingChanges, setNoPendingChanges] = useState<boolean>(true);

  const pendingChanges = useSelector(
    (state: RootState) => state.nodeRecords.pendingChanges
  );

  const selectedNode = useSelector((state: RootState) =>
    selectCurrentNode(state)
  );

  const formInvalid = useSelector(
    (state: RootState) => state.nodeRecords.formInvalid
  );

  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);

  const childrenNodes = useSelector((state: RootState) =>
    selectChildrenByParentId(state, state.nodeRecords.selectedNodeId as number)
  );

  useEffect(() => {
    let noPendingChanges = true;
    for (const key in pendingChanges) {
      if (pendingChanges[key as keyof typeof pendingChanges]) {
        noPendingChanges = false;
      }
    }
    setNoPendingChanges(noPendingChanges);
  }, [pendingChanges]);

  const onEditClick = () => {
    dispatch({ type: setEditCurrentNode.type, payload: true });
  };

  const onDeleteClick = () => {
    dispatch(deleteNodeAction());
  };

  const onSaveClick = () => {
    setSaving(true);
    if (selectedNode?.newNode) {
      dispatch(postNewNodeAction());
    } else {
      dispatch(editNodeAction());
    }
    setSaving(false);
  };

  useEffect(() => {
    if (childrenNodes.length > 0) {
      setDeleteDisabled(true);
    } else {
      setDeleteDisabled(false);
    }
  }, [childrenNodes]);

  return (
    <div className="node-edit-actions">
      {editCurrentNode ? (
        <>
          <PrimaryButton
            type="link"
            icon={saving ? <SyncOutlined spin /> : <SaveOutlined />}
            disabled={noPendingChanges || formInvalid}
            onClick={onSaveClick}
          />
          <PrimaryButton
            type="link"
            icon={<UndoOutlined />}
            disabled={noPendingChanges}
          />
        </>
      ) : (
        <>
          <PrimaryButton
            type="link"
            icon={<EditOutlined />}
            onClick={onEditClick}
          />
          <PrimaryButton
            type="link"
            disabled={deleteDisabled || saving}
            icon={<DeleteOutlined />}
            onClick={onDeleteClick}
          />
        </>
      )}
    </div>
  );
};

export default NodeEditActions;
