import {
  EntityState,
  PayloadAction,
  Update,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import {
  PatNodeRecordInfo,
  UserManagementRecord,
} from "./node-tree.defenitions";
import { getNodeInfo } from "./node-tree.api";

const nodeRecordUsersAdapter = createEntityAdapter<UserManagementRecord>();

export const nodeRecordUsersSelectors = nodeRecordUsersAdapter.getSelectors();

const nodeRecordUsersSlice = createSlice({
  name: "nodeRecordUsers",
  initialState: nodeRecordUsersAdapter.getInitialState(),
  reducers: {
    editUser(
      slice: EntityState<UserManagementRecord>,
      action: PayloadAction<Update<UserManagementRecord>>
    ) {
      nodeRecordUsersAdapter.updateOne(slice, action.payload);
    },
    deleteUser(
      slice: EntityState<UserManagementRecord>,
      action: PayloadAction<{ id: number }>
    ) {
      nodeRecordUsersAdapter.removeOne(slice, action.payload.id);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNodeInfo.pending,
      (slice: EntityState<UserManagementRecord>) => {
        nodeRecordUsersAdapter.removeAll(slice);
      }
    );

    builder.addCase(
      getNodeInfo.fulfilled,
      (
        slice: EntityState<UserManagementRecord>,
        action: PayloadAction<PatNodeRecordInfo>
      ) => {
        nodeRecordUsersAdapter.setAll(slice, action.payload.users);
      }
    );
  },
});

export default nodeRecordUsersSlice.reducer;

export const { editUser, deleteUser } = nodeRecordUsersSlice.actions;
