import React from "react";

const TableCell: React.FC<CellProps> = ({ children }) => {
  return <td>{children}</td>;
};

export default TableCell;

interface CellProps {
  children: React.ReactNode;
}
