import { useMemo } from "react";
import { NodeUserProps } from "./NodeUser";
import { Button, Dropdown, MenuProps } from "antd";
import { FormattedMessage } from "react-intl";
import { PlethoraApp } from "src/general.defenitions";
import { getUserAuthToken } from "./node-user.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/store";

const isProd = window.location.hostname === "www.pat.iamplethora.com";

const scienceRedirectUrl = isProd
  ? "https://www.science.iamplethora.com/?token="
  : "https://science-dev.iamplethora.com/?token=";
const ctRedirectUrl = `https://${
  isProd ? "www" : "stage"
}.iamplethora.com/sso.html?source=pat&token=`;

const LoginAsUser = ({ user, nodeId }: NodeUserProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const items = useMemo(() => {
    return [
      {
        key: PlethoraApp.CT,
        label: <FormattedMessage id="user.loginAs.ct" />,
      },
      {
        key: PlethoraApp.SCIENCE,
        label: <FormattedMessage id="user.loginAs.science" />,
      },
    ];
  }, []);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    dispatch(
      getUserAuthToken({ nodeId, userId: user.id, targetApp: key })
    ).then((response) => {
      const token = response.payload;
      // key is PlethoraApp, we should open a new tab
      // and redirect user to the corresponding app
      window.open(
        key === PlethoraApp.CT
          ? `${ctRedirectUrl}${token}`
          : `${scienceRedirectUrl}${token}`,
        "_blank"
      );
    });
  };

  return (
    <Dropdown menu={{ items, onClick }}>
      <Button type="text" key="edit">
        <FormattedMessage id="user.loginAs" />
      </Button>
    </Dropdown>
  );
};

export default LoginAsUser;
