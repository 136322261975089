import { Form, Input, InputRef } from "antd";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "./EditableRow";
import { LicenseFormItem } from "./licenses.defenitions";
import { ColumnTitle } from "antd/lib/table/interface";
import "./Editable.scss";

const EditableTextCell: React.FC<EditableCellProps> = ({
  title,
  children,
  dataIndex,
  record,
  width = "auto",
  handleSave,
  type,
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    setEditing(false);
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values, changed: true });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  return editing ? (
    <Form.Item style={{ margin: 0, width }} name={dataIndex}>
      <Input
        className="hide-arrows"
        ref={inputRef}
        onPressEnter={save}
        onBlur={save}
        type={type}
      />
    </Form.Item>
  ) : (
    <div
      className="editable-cell-value-wrap"
      style={{ paddingRight: 24 }}
      onClick={toggleEdit}
    >
      {children ? children : "-"}
    </div>
  );
};

interface EditableCellProps {
  title: ColumnTitle<LicenseFormItem>;
  children: ReactElement;
  width?: string | number;
  type: "string" | "number";
  dataIndex: keyof LicenseFormItem;
  record: LicenseFormItem;
  handleSave: (record: LicenseFormItem) => void;
}

export default EditableTextCell;
