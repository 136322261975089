import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { RootState } from "src/store";
import { apiCall, apiUrls } from "src/store/api";
import {
  NodeChangeableData,
  setSelectedNodeId,
} from "./node-tree-records.slice";
import {
  NodeRecord,
  NodeRecordAddedResponse,
  PatNodeRecordInfo,
} from "./node-tree.defenitions";

export const getNodeTree = createAsyncThunk<
  any,
  undefined,
  { state: RootState }
>("getNodeTree", async (param, { getState }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "GET",
    url: apiUrls.nodeTree,
    token,
  });
  return response.data;
});

export const getNodeInfo = createAsyncThunk<
  PatNodeRecordInfo,
  number,
  { state: RootState }
>("getNodeInfo", async (nodeId, { getState, dispatch }) => {
  const token = getState().persistentUser.token;
  dispatch({ type: setSelectedNodeId.type, payload: nodeId });
  const response = (await apiCall({
    method: "GET",
    url: apiUrls.node(nodeId),
    token,
  })) as AxiosResponse<PatNodeRecordInfo>;
  return response.data;
});

export const addNodeBackend = createAsyncThunk<
  NodeRecordAddedResponse,
  NodeRecord,
  { state: RootState }
>("addNodeToBackend", async (node, { getState }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "POST",
    url: apiUrls.addNode,
    data: node,
    token,
  });
  return response.data;
});

export const deleteNodeBackend = createAsyncThunk<
  NodeRecordAddedResponse,
  number,
  { state: RootState }
>("addNodeToBackend", async (nodeId, { getState }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "DELETE",
    url: apiUrls.node(nodeId),
    token,
  });
  return response.data;
});

export const editNodeBackend = createAsyncThunk<
  any,
  NodeRecord & NodeChangeableData,
  { state: RootState }
>("editNodeBackend", async (nodeUpdate, { getState }) => {
  const token = getState().persistentUser.token;
  const response = await apiCall({
    method: "PATCH",
    url: apiUrls.node(nodeUpdate.id as number),
    data: nodeUpdate,
    token,
  });
  return response.data;
});
