import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  Method,
} from "axios";

const DEV_URL = "https://w2i02zc2j3.execute-api.us-east-1.amazonaws.com/dev";
const PROD_URL = "https://ko6s75wurc.execute-api.us-east-1.amazonaws.com/prod";

const DEV_KEY = "y9xYN8Q7OY1mZKQZZfbUL7pkPrYuNiWv62Kv6y3O";
const PROD_KEY = "FeRLhxsOJu5pnnIztYTy5DHJwnIXobu5Mq5KnTZ0";

let STAGE_URL = DEV_URL;
let STAGE_KEY = DEV_KEY;

if (process.env.REACT_APP_STAGE === "prod") {
  STAGE_URL = PROD_URL;
  STAGE_KEY = PROD_KEY;
}

// axios instance for making API calls
// it gets intercepted in the MainRouter
export const api = axios.create({ baseURL: STAGE_URL });

export const apiUrls = {
  authenticateWithPassword: `${STAGE_URL}/authenticate/with-password`,
  nodeTree: `${STAGE_URL}/node-tree`,
  addNode: `${STAGE_URL}/node-tree/add-node`,
  node: (nodeId: number) => `${STAGE_URL}/node-tree/${nodeId}`,
  nodeUser: (nodeId: number, userId: number) =>
    `${STAGE_URL}/node-tree/${nodeId}/users/${userId}`,
  licenses: `${STAGE_URL}/licenses`,
  loginAsUser: (nodeId: number, userId: number, targetApp: string) =>
    `${STAGE_URL}/node-tree/${nodeId}/users/${userId}/auth-as-user/${targetApp}`,
};

export const apiCall = ({
  method,
  url,
  token,
  customHeaders,
  data,
}: ApiCallRequest): AxiosPromise<any> => {
  const headers: AxiosRequestHeaders = {
    Accept: "application/json",
    "x-api-key": STAGE_KEY,
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (customHeaders) {
    for (let header in customHeaders) {
      headers[header] = customHeaders[header];
    }
  }

  const config: AxiosRequestConfig = {
    method,
    url,
    data,
    headers,
  };

  return api(config);
};

interface ApiCallRequest {
  method: Method;
  url: string;
  token?: string | null;
  customHeaders?: { [key: string]: string };
  data?: any;
}
