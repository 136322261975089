import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../store";

const RequireAuth = () => {
  const token = useSelector((state: RootState) => state.persistentUser.token);
  return token ? <></> : <Navigate to="/login" replace />;
};

export default React.memo(RequireAuth);
